import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { BatchData, Connection, RequestFiltersParams, UseQueryWrapper } from 'app/types';
import connectionService from '../../../Domain/Connections/Services/ConnectionService';
import { useSnakeBar } from '../../useSnakeBar';

type GetBatchCompareDataQueryVariables =
  | Omit<RequestFiltersParams, 'filters' | 'sortBy'> & {
      batchId: string | undefined;
      connectionId: Connection['connectionId'] | undefined;
    };

type GetBatchCompareDataQueryResultData = {
  data: Array<BatchData>;
  totalCount: number;
};

const queryFn =
  ({ batchId, connectionId, ...others }: GetBatchCompareDataQueryVariables) =>
  async () => {
    if (batchId && connectionId) {
      const response = await connectionService.getBatchCompareData({
        batchId,
        connectionId,
        ...others,
      });
      return response.data;
    }
  };

const QUERY_KEY = 'getBatchCompareData';

export const useConnectionGetBatchCompareDataQuery: UseQueryWrapper<
  GetBatchCompareDataQueryResultData,
  GetBatchCompareDataQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(
    error => {
      if (error.response?.status !== 422) {
        showErrorSnakeBar({ method: QUERY_KEY });
      }
    },
    [showErrorSnakeBar],
  );

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetBatchCompareDataQueryResultData>(
    [QUERY_KEY, ...Object.values(variables || {})],
    queryFn(variables),
    {
      select,
      onError,
      retry: (failureCount: number, error: any) => {
        return error.response?.status !== 422 && failureCount < 3;
      },
      ...(options || {}),
    },
  );
};
