import { styled } from '@mui/material/styles';

import { commonTableHoverEffectStyles } from '../List.styles';

export const DivRowStyled = styled('div', {
  name: 'DivRowStyled',
  shouldForwardProp: (propName: string) => !['innerRow'].includes(propName),
})<{ innerRow: boolean }>(({ theme, innerRow }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  minWidth: '100%',
  minHeight: '2em',
  ...(!innerRow ? { ...commonTableHoverEffectStyles(theme) } : {}),
  ...(innerRow
    ? {
        position: 'relative',
        '&::after': {
          position: 'absolute',
          content: '""',
          borderBottom: `1px solid ${theme.palette.secondary.main}`,
          left: '74px',
          right: 0,
          bottom: 0,
        },
      }
    : {}),
}));
