import React from 'react';

import { Checkbox } from '../../Checkbox';

import { DivRowStyled } from './ListRow.styles';

export const LIST_ROW_DATA_TEST_ID = 'list-row';

type ListRowProps = {
  children: React.ReactNode;
  onSelect?: () => void;
  selected?: boolean;
  disabled?: boolean;
  innerRow?: boolean;
};

export const ListRow = ({ children, onSelect, selected = false, disabled = false, innerRow = false }: ListRowProps) => {
  return (
    <DivRowStyled data-testid={LIST_ROW_DATA_TEST_ID} innerRow={innerRow}>
      {!!onSelect && <Checkbox name={'list-row-check'} checked={selected} onChange={onSelect} disabled={disabled} />}
      {children}
    </DivRowStyled>
  );
};
