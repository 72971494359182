import React from 'react';
import { Grid } from '@mui/material';
import { NotesInput } from 'app/components';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from 'app/store';
import { actions as NotesActions } from '../../Ducks/Notes.duck';
import type { Connection } from '../../../../types';

type ConnectionNoteInputProps = {
  connectionId: Connection['connectionId'];
};

export const ConnectionNoteInput = ({ connectionId }: ConnectionNoteInputProps) => {
  const dispatch = useStoreDispatch();

  const {
    loading,
    note,
    connectionId: storeConnectionId,
  } = useStoreSelector(state => StoreSelectors.NotesSelector.selectConnectionNote(state.NotesReducer));

  const [noteText, setNoteText] = React.useState(note.text);
  const [updating, setUpdating] = React.useState(false);

  const firstLoad =
    note.connectionId !== storeConnectionId || note.connectionId !== connectionId || storeConnectionId !== connectionId;

  const waiting = loading || firstLoad;

  React.useEffect(() => {
    if (connectionId && storeConnectionId !== connectionId) {
      dispatch(StoreActions.NotesActions.setConnectionId(connectionId));
      dispatch(StoreActions.NotesActions.requestData());
    }
  }, [connectionId, dispatch, storeConnectionId]);

  React.useEffect(() => {
    if (firstLoad) {
      setUpdating(false);
    }
  }, [firstLoad]);

  React.useEffect(() => {
    if (!updating) {
      setNoteText(note.text);
    }
  }, [updating, loading, note.text]);

  const saveNote = React.useCallback(
    text => {
      setNoteText(text);
      setUpdating(true);
      if (loading) {
        return;
      }
      dispatch(NotesActions.changeNote({ ...note, connectionId, text: text }));
    },
    [connectionId, dispatch, loading, note],
  );

  // Uncomment this if you want to add again that only the user that created the note can actually edit it
  // const userCanEdit = (authorId) => authorId === user.userId
  const userCanEdit = (authorId: string) => true;
  const isNotAllowed = note && note.userId ? !userCanEdit(note.userId) : false;

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <NotesInput
          isLoading={waiting}
          isNotAllowed={isNotAllowed}
          inputValue={firstLoad ? '' : noteText}
          onSaveNote={saveNote}
        />
      </Grid>
    </Grid>
  );
};
