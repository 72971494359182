import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Checkbox } from '../../Checkbox';

import { ListRow } from '../ListRow';

import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  DivRowContainerStyled,
} from './ListCollapsibleRow.styles';

export const LIST_COLLAPSIBLE_ROW_DATA_TEST_ID = 'list-collapsible-row-component';

type ListCollapsibleRowProps = {
  rowHeader: React.ReactNode;
  children: React.ReactNode;
  onSelect?: () => void;
  selected?: boolean;
  disabled?: boolean;
  childrenExtraLeftPadding?: boolean;
};

export const ListCollapsibleRow = ({
  rowHeader,
  children,
  onSelect,
  selected = false,
  disabled = false,
  childrenExtraLeftPadding = false,
}: ListCollapsibleRowProps) => {
  return (
    <DivRowContainerStyled data-testid={LIST_COLLAPSIBLE_ROW_DATA_TEST_ID}>
      {!!onSelect && (
        <Checkbox name={'list-collapsible-row-check'} checked={selected} onChange={onSelect} disabled={disabled} />
      )}

      <AccordionStyled>
        <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
          <ListRow>{rowHeader}</ListRow>
        </AccordionSummaryStyled>

        <AccordionDetailsStyled extraLeftPadding={childrenExtraLeftPadding}>{children}</AccordionDetailsStyled>
      </AccordionStyled>
    </DivRowContainerStyled>
  );
};
