import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';

import { Tooltip } from 'app/components/Tooltip';

type CalculationCardValueWithFormulaTooltipProps = {
  value: string | React.ReactNode;
  formulaId: string;
};

export const CalculationCardValueWithFormulaTooltip = ({
  value,
  formulaId,
}: CalculationCardValueWithFormulaTooltipProps) => {
  const title = React.useMemo(() => {
    return (
      <Typography variant="body1">
        <FormattedMessage id={formulaId} />
      </Typography>
    );
  }, [formulaId]);

  return (
    <Tooltip title={title} placement="right-end">
      <span>{value}</span>
    </Tooltip>
  );
};
