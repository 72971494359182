import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { EmployeeNote, UseQueryWrapper } from 'app/types';

import { useSnakeBar } from 'app/hooks/useSnakeBar';
import type { GetEmployeeNotesParams } from '../../../Domain/Employees/Services/EmployeeService';
import EmployeeService from '../../../Domain/Employees/Services/EmployeeService';

export type GetEmployeeNotesQueryResultData = EmployeeNote;

export type GetEmployeeNotesQueryVariables = Partial<GetEmployeeNotesParams>;

const queryFn =
  ({ employerId, employeeId }: GetEmployeeNotesQueryVariables) =>
  async () => {
    if (employerId && employeeId) {
      const response = await EmployeeService.getEmployeeNotes({ employerId, employeeId });
      return response.data;
    }
  };

const QUERY_KEY = 'getEmployeeNotes';

export const useEmployeeGetNotesQuery: UseQueryWrapper<
  GetEmployeeNotesQueryResultData,
  GetEmployeeNotesQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  return useQuery<GetEmployeeNotesQueryResultData>([QUERY_KEY, ...Object.values(variables || {})], queryFn(variables), {
    onError,
    ...(options || {}),
  });
};
