import React from 'react';

import type { EmployeeCalculationsAdditionalSavings } from 'app/hooks';

import { TranslationKeys } from 'app/translations';
import type { CalculationsCardProps } from '../CalculationsCard';
import { CalculationsCard } from '../CalculationsCard';

type EmployeePensionAdditionalSavingsDetailsPanelProps = {
  loading?: boolean;
  data: EmployeeCalculationsAdditionalSavings | undefined;
};

export const EmployeePensionAdditionalSavingsDetailsPanel = ({
  loading,
  data,
}: EmployeePensionAdditionalSavingsDetailsPanelProps) => {
  const cardFields = React.useMemo<CalculationsCardProps['fields']>(() => {
    if (data && 'message' in data) {
      return [];
    }

    return [
      {
        labelId: TranslationKeys.employees_pension_premiumPerYear,
        formulaId: TranslationKeys.employees_pension_calcFormula_additionalSavings_premiumPerYear,
        value: {
          value: data?.premiumPerYear,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_premiumPerMonth,
        formulaId: TranslationKeys.employees_pension_calcFormula_additionalSavings_premiumPerMonth,
        value: {
          value: data?.premiumPerMonth,
          type: 'currency',
        },
      },
    ];
  }, [data]);

  return (
    <CalculationsCard
      isLoading={loading}
      titleId={TranslationKeys.employees_detail_additionalSavings}
      fields={cardFields}
      error={data?.message}
    />
  );
};
