import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Box, Grid, Link, Paper, Skeleton, Switch, Typography } from '@mui/material';

import { actions as AppActions } from '../../../App/Ducks/App.duck';
import { MESSAGE_SEVERITY_ERROR } from '../../../../common/constants';

import employerService from '../../Services/EmployerService';

import CreateEmployerForm from '../../Forms/CreateEmployerForm';
import CreateSubEmployerForm from '../../Forms/CreateSubEmployerForm';

import { normalizeError } from 'app/utils';
import { useNavigate } from 'react-router-dom';
import { useBreadcrumb } from 'app/components/Breadcrumbs';
import { default as MuiTextField } from '@mui/material/TextField';
import { actions as OwnersActions } from '../../../Owners/Ducks/Owners.duck';
import { Uuid } from '../../../../utils/uuid';

import {
  AutocompleteStyled,
  ButtonStyled,
  DividerStyled,
  FormLabelStyled,
  PaperStyled,
  TypographyDescriptionStyled,
  TypographyQuestionStyled,
  TypographySubEmployerHeaderStyled,
} from './EmployerAddPage.styles';

const EmployerAddPage = () => {
  // uses
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ownerId = useSelector(state => state.AppReducer.environment.ownerId);
  const userId = useSelector(state => state.AppReducer.user.userId);

  // data
  const [employer, setEmployer] = useState({
    employerId: Uuid.newV4(),
    employerName: '',
    employerEmail: '',
    employerPhone: '',
    internalNumber: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    chamberOfCommerce: '',
    sectorCode: '',
    payrollTaxNumber: '',
    address: '',
    zipCode: '',
    city: '',
    numberOfEmployees: 0,
    ownerId: null,
    subEmployers: [],
    parentId: null,
  });

  const [employerIsHolding, setEmployerIsHolding] = useState(false);
  const [employerIsSubEmployer, setEmployerIsSubEmployer] = useState(false);
  const [employersTotalCount, setEmployersTotalCount] = useState({});
  const [employersHolding, setEmployersHolding] = useState([]);
  const [subOwners, setSubOwners] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [selectedParent, setSelectedParent] = useState(null);
  const { items: owners } = useSelector(state => state.OwnersReducer);

  useEffect(() => {
    dispatch(OwnersActions.requestData());
  }, [dispatch]);

  const loadTotalCount = useCallback(async () => {
    try {
      const response = await employerService.getOwnerEmployersTotalCount(userId);
      setEmployersTotalCount(response.data);
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getEmployersTotalCount',
        severity,
      };
      dispatch(AppActions.displayMessage(details));
    }
  }, [dispatch, userId]);

  const saveEmployer = useCallback(async values => {
    return employerService.createEmployer(values);
  }, []);

  // effects
  useEffect(() => {
    loadTotalCount();
  }, [loadTotalCount]);

  // events
  const handleChange = useCallback(
    (field, value) => {
      const newEmployer = { ...employer };
      newEmployer[field] = value;

      setEmployer(newEmployer);
    },
    [employer],
  );

  const handleChangeParent = useCallback(
    parent => {
      setEmployer({
        ...employer,
        parentId: parent?.employerId,
      });
    },
    [employer],
  );

  const handleCancel = useCallback(() => {
    navigate('../');
  }, [navigate]);

  const handleCreateEmployer = useCallback(async () => {
    saveEmployer(employer)
      .then(() => navigate('../'))
      .catch(err => {
        const errors = normalizeError(err);
        const severity = MESSAGE_SEVERITY_ERROR;
        const details = {
          method: 'createEmployer',
          severity,
          message: errors.message,
        };
        dispatch(AppActions.displayMessage(details));
      });
  }, [dispatch, navigate, employer, saveEmployer]);

  const handleSwitchEmployerIsHolding = useCallback(() => {
    if (!employerIsHolding && !employer.subEmployers.length) {
      employer.subEmployers.push({
        employerId: Uuid.newV4(),
        employerName: '',
        employerEmail: '',
        employerPhone: '',
        internalNumber: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        chamberOfCommerce: '',
        sectorCode: '',
        payrollTaxNumber: '',
        address: '',
        zipCode: '',
        city: '',
        numberOfEmployees: 0,
        ownerId: employer.ownerId,
        parentId: employer.employerId,
      });
    } else {
      employer.subEmployers = [];
    }

    setEmployerIsHolding(!employerIsHolding);
    setEmployerIsSubEmployer(false);
  }, [employer, employerIsHolding]);

  const handleSwitchEmployerIsSubEmployer = useCallback(() => {
    setEmployerIsHolding(false);
    setEmployerIsSubEmployer(!employerIsSubEmployer);
    handleChangeParent(null);
    setSelectedParent(null);
  }, [employerIsSubEmployer, handleChangeParent]);

  const handleClickAddAnother = useCallback(() => {
    employer.subEmployers.push({
      employerId: Uuid.newV4(),
      employerName: '',
      employerEmail: '',
      employerPhone: '',
      internalNumber: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      chamberOfCommerce: '',
      sectorCode: '',
      payrollTaxNumber: '',
      address: '',
      zipCode: '',
      city: '',
      ownerId: ownerId,
      parentId: employer.employerId,
    });
  }, [employer, ownerId]);

  const handleDeleteSubEmployer = useCallback(
    employerId => {
      const newSubEmployers = employer.subEmployers.filter(se => se.employerId !== employerId);

      setEmployer({
        ...employer,
        subEmployers: newSubEmployers,
      });
    },
    [employer],
  );

  const title = useMemo(() => {
    return (
      <div>
        <Typography variant="h5">
          <FormattedMessage id="employers.add" />
        </Typography>
        <Typography variant="subtitle2" textTransform={'uppercase'}>
          {typeof employersTotalCount?.total !== 'undefined' ? (
            <FormattedMessage id="employers.totalCount" values={{ totalCount: employersTotalCount.total }} />
          ) : (
            <Skeleton width={200} />
          )}
        </Typography>
      </div>
    );
  }, [employersTotalCount]);

  useBreadcrumb('employers.add', {
    hideTrail: true,
    titleElement: title,
  });

  const parentIdAutoComplete = useMemo(() => {
    const reducedOptions = employersHolding.reduce(function (filtered, employer) {
      if (employer.parentId === null) {
        filtered.push(employer);
      }
      return filtered;
    }, []);
    reducedOptions.sort((a, b) => (a.employerName > b.employerName ? 1 : b.employerName > a.employerName ? -1 : 0));

    const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
      const { ...rest } = props;

      return (
        <div>
          <ul ref={ref} {...rest} />
          <TypographyDescriptionStyled variant="body1">
            <FormattedMessage
              id="employers.add.subEmployers.holding.description"
              values={{ b: chunks => <b>{chunks}</b>, br: <br /> }}
            />
          </TypographyDescriptionStyled>
        </div>
      );
    });

    return (
      <AutocompleteStyled
        name="parentId"
        value={selectedParent}
        options={reducedOptions}
        getOptionLabel={option => {
          return option.employerName;
        }}
        disableClearable={true}
        isOptionEqualToValue={(item, current) => {
          if (typeof current === 'string') {
            return item.value === current;
          }
          return item.employerId === current.employerId;
        }}
        onChange={(e, value) => {
          setSelectedParent(value);
          handleChangeParent(value);
        }}
        renderInput={params => <MuiTextField {...params} name="parentId" variant="outlined" fullWidth />}
        ListboxComponent={listboxProps => <ListboxComponent {...listboxProps} />}
      />
    );
  }, [employersHolding, handleChangeParent, selectedParent]);
  // template
  return (
    <Paper elevation={0} square>
      <Grid container spacing={3}>
        {/* employer form */}
        <Grid item xs={6}>
          <PaperStyled isInfo>
            <CreateEmployerForm
              employer={employer}
              employerIsHolding={false}
              employerShowHolding={false}
              onEmployerChange={handleChange}
              owners={owners}
              setEmployersHolding={setEmployersHolding}
              setSubOwners={setSubOwners}
              subOwners={subOwners}
              setSelectedOwner={setSelectedOwner}
              selectedOwner={selectedOwner}
              setSelectedParent={setSelectedParent}
              setEmployer={setEmployer}
            />
          </PaperStyled>
        </Grid>

        {/* employer additional info */}
        <Grid item xs={6}>
          <PaperStyled>
            {/* type switches */}
            <div>
              <TypographyQuestionStyled variant="body1">
                <FormattedMessage id="employers.holding.question" values={{ b: chunks => <b>{chunks}</b> }} />
              </TypographyQuestionStyled>
              <FormLabelStyled>
                <FormattedMessage id="employers.holding" />
              </FormLabelStyled>
              <div>
                <Switch checked={employerIsHolding} onChange={handleSwitchEmployerIsHolding} />
              </div>
            </div>

            {!employerIsHolding && (
              <div>
                <TypographyQuestionStyled variant="body1">
                  <FormattedMessage id="employers.holdingPart.question" values={{ b: chunks => <b>{chunks}</b> }} />
                </TypographyQuestionStyled>
                <FormLabelStyled>
                  <FormattedMessage id="employers.holdingPart" />
                </FormLabelStyled>
                <div>
                  <Switch checked={employerIsSubEmployer} onChange={handleSwitchEmployerIsSubEmployer} />
                </div>
              </div>
            )}

            {/* employer HAS subEmployers */}
            {!!employerIsHolding && (
              <TypographySubEmployerHeaderStyled variant="body1">
                <FormattedMessage
                  id="employers.add.subEmployers.description"
                  values={{ totalCount: employersTotalCount.total }}
                />
              </TypographySubEmployerHeaderStyled>
            )}

            {!!employerIsHolding &&
              !!employer.subEmployers.length &&
              employer.subEmployers.map((subEmployer, subEmployerIndex) => (
                <Grid container key={subEmployerIndex}>
                  <CreateSubEmployerForm
                    employer={employer}
                    subEmployer={subEmployer}
                    onDelete={handleDeleteSubEmployer}
                  />

                  <DividerStyled />
                </Grid>
              ))}

            {!!employerIsHolding && (
              <Link
                sx={{ textAlign: 'right', width: '100%' }}
                href="EmployerAddPage#"
                onClick={handleClickAddAnother}
                style={{ display: 'none' }}
              >
                <Typography variant="h6">
                  <FormattedMessage id="employers.add.subEmployers.button" />
                </Typography>
              </Link>
            )}

            {/* employer IS subEmployer */}
            {!!employerIsSubEmployer && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormLabelStyled>
                    <FormattedMessage id="employers.add.subEmployers.holding" />
                  </FormLabelStyled>
                  {parentIdAutoComplete}
                </Grid>
              </Grid>
            )}
          </PaperStyled>
        </Grid>

        {/* buttons */}
        <Grid item xs={12}>
          <Box textAlign="right">
            <ButtonStyled onClick={handleCancel} variant="outlined">
              <FormattedMessage id="button.cancel" />
            </ButtonStyled>

            <ButtonStyled
              disabled={
                !employer.employerName || !employer.ownerId || !employer.zipCode || !employer.city || !employer.address
              }
              onClick={handleCreateEmployer}
              variant={'contained'}
            >
              <FormattedMessage id="employers.add.createNewEmployer" />
            </ButtonStyled>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EmployerAddPage;
