import React from 'react';
import { useIntl } from 'react-intl';

import { TranslationKeys } from 'app/translations';

import { CivilStatusEnum } from 'app/types';

export const useCivilStatusSelectItems = () => {
  const intl = useIntl();

  const civilStatusSelectItems = React.useMemo(() => {
    return [
      {
        element: intl.formatMessage({ id: TranslationKeys.global_civilStatus_single }),
        value: CivilStatusEnum.Single,
      },
      {
        element: intl.formatMessage({ id: TranslationKeys.global_civilStatus_married }),
        value: CivilStatusEnum.Married,
      },
      {
        element: intl.formatMessage({ id: TranslationKeys.global_civilStatus_livingTogetherWithAgreement }),
        value: CivilStatusEnum.LivingTogetherWithAgreement,
      },
      {
        element: intl.formatMessage({ id: TranslationKeys.global_civilStatus_livingTogetherWithoutAgreement }),
        value: CivilStatusEnum.LivingTogetherWithoutAgreement,
      },
      {
        element: intl.formatMessage({ id: TranslationKeys.global_civilStatus_registeredPartnership }),
        value: CivilStatusEnum.RegisteredPartnership,
      },
    ];
  }, [intl]);

  const getCivilStatusDisplayNameByValue = React.useCallback(
    (value: CivilStatusEnum) => {
      return civilStatusSelectItems.find(civilStatus => civilStatus.value === value)?.element;
    },
    [civilStatusSelectItems],
  );

  return React.useMemo(
    () => ({ civilStatusSelectItems, getCivilStatusDisplayNameByValue }),
    [civilStatusSelectItems, getCivilStatusDisplayNameByValue],
  );
};
