import React from 'react';
import { useParams } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import type { PaginationProps } from 'app/components';
import { ConfirmDialog, useBreadcrumb } from 'app/components';
import { TranslationKeys } from 'app/translations';
import {
  useConnectionApproveDraftMutation,
  useConnectionArchiveEventsFromDraftMutation,
  useConnectionGetBatchCompareDataQuery,
  useConnectionMarkAsCompleteMutation,
  useDialog,
  useProcessDeclineMutation,
  useSnakeBar,
} from 'app/hooks';
import { CompareBatchPageContent } from './components';
import { useNavigate } from 'react-router-dom';
import { useConnectionResendMutationsMutation } from '../../../../hooks/api/connection/useConnectionResendMutationsMutation';
import { useConnectionDeclineMutationsMutation } from '../../../../hooks/api/connection/useConnectionDeclineMutationsMutation';

type Filters = {
  searchQuery: string;
  page: PaginationProps['page'];
  rowsPerPage: PaginationProps['rowsPerPage'];
};

type FiltersMap = Map<keyof Filters, Filters[keyof Filters]>;

type CompareBatchPageParams = {
  employerId: string;
  connectionId: string;
  batchId: string;
};

export const CompareBatchPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { connectionId, batchId, employerId } = useParams<CompareBatchPageParams>();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const { mutate: approveDraftBatchMutation } = useConnectionApproveDraftMutation();
  const { mutate: archiveFromDraftBatchMutation } = useConnectionArchiveEventsFromDraftMutation();
  const { mutate: markAsComplete } = useConnectionMarkAsCompleteMutation();
  const { mutate: resendMutations } = useConnectionResendMutationsMutation();
  const { mutate: declineMutations } = useConnectionDeclineMutationsMutation();
  const { mutate: processDeclineMutation } = useProcessDeclineMutation();

  const {
    dialogState: showConfirmApproveDialog,
    openDialog: openConfirmApproveDialog,
    closeDialog: closeConfirmApproveDialog,
  } = useDialog();

  const {
    dialogState: showConfirmArchiveDialog,
    openDialog: openConfirmArchiveDialog,
    closeDialog: closeConfirmArchiveDialog,
  } = useDialog();

  const taskId = undefined;
  const [selected, setSelected] = React.useState<Set<string>>(new Set<string>([]));

  const [filters, setFilters] = React.useState<FiltersMap>(() => {
    const defaultState = new Map<keyof Filters, Filters[keyof Filters]>();
    defaultState.set('searchQuery', '');
    defaultState.set('page', 1);
    defaultState.set('rowsPerPage', 10);
    return defaultState;
  });

  const searchQuery = filters.get('searchQuery') as string;
  const page = filters.get('page') as number;
  const rowsPerPage = filters.get('rowsPerPage') as number;

  const {
    data: batchCompareData,
    isLoading: isGetBatchCompareDataQueryLoading,
    isFetching: isGetBatchCompareDataQueryFetching,
    refetch: refetchGetBatchCompareDataQuery,
  } = useConnectionGetBatchCompareDataQuery({
    variables: {
      batchId,
      connectionId,
      searchQuery,
      page,
      pageSize: rowsPerPage,
    },
    options: {
      enabled: !!batchId && !!connectionId,
    },
  });

  const onConfirmApproveBatch = React.useCallback(() => {
    if (batchId && connectionId && selected.size > 0) {
      const method = 'approveDraftBatch';

      approveDraftBatchMutation(
        {
          batchId,
          connectionId,
          externalIds: Array.from(selected),
        },
        {
          onSuccess: () => {
            showSuccessSnakeBar({
              method,
              message: intl.formatMessage({ id: TranslationKeys.connection_processes_compareBatch_successApprovedMsg }),
            });
            refetchGetBatchCompareDataQuery();
          },
          onError: error => {
            // eslint-disable-next-line no-console
            console.error(error);
            showErrorSnakeBar({ method, message: intl.formatMessage({ id: TranslationKeys.global_errorMsg }) });
          },
        },
      );
    }
  }, [
    approveDraftBatchMutation,
    batchId,
    connectionId,
    intl,
    selected,
    showErrorSnakeBar,
    showSuccessSnakeBar,
    refetchGetBatchCompareDataQuery,
  ]);

  const onConfirmArchiveBatch = React.useCallback(() => {
    if (batchId && connectionId && selected.size > 0) {
      const method = 'archiveDraftBatch';

      archiveFromDraftBatchMutation(
        {
          batchId,
          connectionId,
          externalIds: Array.from(selected),
        },
        {
          onSuccess: () => {
            showSuccessSnakeBar({
              method,
              message: intl.formatMessage({ id: TranslationKeys.connection_processes_compareBatch_successArchivedMsg }),
            });
            refetchGetBatchCompareDataQuery();
          },
          onError: error => {
            // eslint-disable-next-line no-console
            console.error(error);
            showErrorSnakeBar({ method, message: intl.formatMessage({ id: TranslationKeys.global_errorMsg }) });
          },
        },
      );
    }
  }, [
    archiveFromDraftBatchMutation,
    batchId,
    connectionId,
    intl,
    selected,
    showErrorSnakeBar,
    showSuccessSnakeBar,
    refetchGetBatchCompareDataQuery,
  ]);

  const onConfirmMarkAsCompleted = React.useCallback(() => {
    if (batchId && connectionId) {
      const method = 'markBatchAsCompleted';

      markAsComplete(
        { batchId, connectionId },
        {
          onSuccess: () => {
            navigate(`employers/${employerId}/connections/${connectionId}/info`, { replace: true });
          },
          onError: error => {
            // eslint-disable-next-line no-console
            console.error(error);
            showErrorSnakeBar({ method, message: intl.formatMessage({ id: TranslationKeys.global_errorMsg }) });
          },
        },
      );
    }
  }, [markAsComplete, batchId, connectionId, employerId, intl, showErrorSnakeBar, navigate]);

  const onConfirmResendMutations = React.useCallback(() => {
    if (batchId && connectionId) {
      const method = 'resendMutations';

      resendMutations(
        {
          batchId,
          connectionId,
          externalIds: Array.from(selected),
        },
        {
          onSuccess: () => {
            markAsComplete(
              { batchId, connectionId },
              {
                onSuccess: () => {
                  navigate(`employers/${employerId}/connections/${connectionId}/info`, { replace: true });
                },
                onError: error => {
                  // eslint-disable-next-line no-console
                  console.error(error);
                  showErrorSnakeBar({ method, message: intl.formatMessage({ id: TranslationKeys.global_errorMsg }) });
                },
              },
            );
          },
          onError: error => {
            // eslint-disable-next-line no-console
            console.error(error);
            showErrorSnakeBar({ method, message: intl.formatMessage({ id: TranslationKeys.global_errorMsg }) });
          },
        },
      );
    }
  }, [markAsComplete, resendMutations, batchId, connectionId, employerId, intl, selected, showErrorSnakeBar, navigate]);

  const onConfirmRejectMutations = React.useCallback(() => {
    if (batchId && connectionId) {
      const method = 'rejectMutations';

      declineMutations(
        {
          batchId,
          connectionId,
          externalIds: Array.from(selected),
        },
        {
          onSuccess: () => {
            showSuccessSnakeBar({
              method,
              message: intl.formatMessage({ id: TranslationKeys.connection_processes_compareBatch_rejectMutationsMsg }),
            });
            selected.clear();
            refetchGetBatchCompareDataQuery();
          },
          onError: error => {
            // eslint-disable-next-line no-console
            console.error(error);
            showErrorSnakeBar({ method, message: intl.formatMessage({ id: TranslationKeys.global_errorMsg }) });
          },
        },
      );
    }
  }, [
    batchId,
    connectionId,
    declineMutations,
    selected,
    showSuccessSnakeBar,
    intl,
    refetchGetBatchCompareDataQuery,
    showErrorSnakeBar,
  ]);

  const onConfirmRejectAllMutations = React.useCallback(() => {
    if (batchId && connectionId) {
      const method = 'declineProcess';

      processDeclineMutation(
        {
          connectionId,
          batchId,
        },
        {
          onSuccess: () => {
            navigate(`employers/${employerId}/connections/${connectionId}/events`, { replace: true });
            showSuccessSnakeBar({ method });
          },
          onError: () => {
            showErrorSnakeBar({ method });
          },
        },
      );
    }
  }, [batchId, connectionId, employerId, navigate, processDeclineMutation, showErrorSnakeBar, showSuccessSnakeBar]);

  const startListeningForDataAfterUploadFile = React.useCallback(() => {
    refetchGetBatchCompareDataQuery();
  }, [refetchGetBatchCompareDataQuery]);

  const onSearch = React.useCallback(
    (inputValue: string) => {
      filters.set('searchQuery', inputValue);
      filters.set('page', 1);

      setFilters(new Map(filters));
    },
    [filters],
  );

  const onSelect = React.useCallback((newSelected: Set<string>) => {
    setSelected(new Set(newSelected));
  }, []);

  const onPaginationChange = React.useCallback<NonNullable<PaginationProps['onPaginationChange']>>(
    ({ page, rowSize }) => {
      if (page !== undefined) {
        filters.set('page', page);
      }
      if (rowSize !== undefined) {
        filters.set('rowsPerPage', rowSize);
        filters.set('page', 1);
      }

      setFilters(new Map(filters));
    },
    [filters],
  );

  const paginationProps = React.useMemo<
    Pick<PaginationProps, 'page' | 'rowsPerPage' | 'count' | 'onPaginationChange'>
  >(() => {
    return {
      page,
      rowsPerPage,
      onPaginationChange,
      count: batchCompareData?.totalCount,
    };
  }, [batchCompareData?.totalCount, onPaginationChange, page, rowsPerPage]);

  useBreadcrumb(TranslationKeys.connection_processes_compareBatch);

  const isLoadingGetBatchCompareDataQuery = isGetBatchCompareDataQueryLoading || isGetBatchCompareDataQueryFetching;

  return (
    <>
      <CompareBatchPageContent
        isLoading={isLoadingGetBatchCompareDataQuery}
        waitingForData={!!taskId}
        connectionId={connectionId}
        batchId={batchId}
        batchCompareData={batchCompareData?.data || []}
        selected={selected}
        onSelect={onSelect}
        onSearch={onSearch}
        searchQuery={searchQuery}
        startListeningForDataAfterUploadFile={startListeningForDataAfterUploadFile}
        approveBatch={openConfirmApproveDialog}
        archiveBatch={openConfirmArchiveDialog}
        markAsCompleted={onConfirmMarkAsCompleted}
        resendMutations={onConfirmResendMutations}
        rejectMutations={onConfirmRejectMutations}
        declineBatch={onConfirmRejectAllMutations}
        paginationProps={paginationProps}
      />

      {showConfirmApproveDialog && (
        <ConfirmDialog
          title={<FormattedMessage id={TranslationKeys.connection_processes_compareBatch_approveConfirmDialogTitle} />}
          open={showConfirmApproveDialog}
          onClose={closeConfirmApproveDialog}
          onConfirm={onConfirmApproveBatch}
        >
          <FormattedMessage id={TranslationKeys.connection_processes_compareBatch_approveConfirmDialogBody} />
        </ConfirmDialog>
      )}
      {showConfirmArchiveDialog && (
        <ConfirmDialog
          title={<FormattedMessage id={TranslationKeys.connection_processes_compareBatch_approveConfirmDialogTitle} />}
          open={showConfirmArchiveDialog}
          onClose={closeConfirmArchiveDialog}
          onConfirm={onConfirmArchiveBatch}
        >
          <FormattedMessage id={TranslationKeys.connection_processes_compareBatch_approveConfirmDialogBody} />
        </ConfirmDialog>
      )}
    </>
  );
};
