import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';

import { Grid, Typography } from '@mui/material';

import { TranslationKeys } from 'app/translations';

import format from 'app/utils/formatter';

import { useDialog } from 'app/hooks';

import { InputFieldFactory } from 'app/components/FormikField';
import type { GetInputOptionsAndLabelFunc } from 'app/components';
import { FormikModal, SubmitConfirmationDialog } from 'app/components';

import type { EmployeeEmploymentWageFormValues } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeEmploymentTab/components/EmployeeEmploymentWages/EmployeeEmploymentWages.types';
import {
  EmployeeEmploymentWageFieldOptionsAndLabel,
  EmployeeEmploymentWageFields,
} from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeEmploymentTab/components/EmployeeEmploymentWages/EmployeeEmploymentWages.types';

type EmployeeEmploymentWageFormDialogBodyProps = {
  open: boolean;
  isEdit: boolean;
  closeDialog: () => void;
};

export const EmployeeEmploymentWageFormDialogBody = ({
  open,
  isEdit,
  closeDialog,
}: EmployeeEmploymentWageFormDialogBodyProps) => {
  const { initialValues, values, submitForm, resetForm } = useFormikContext<EmployeeEmploymentWageFormValues>();

  const {
    dialogState: submitConfirmationDialogState,
    openDialog: openSubmitConfirmationDialog,
    closeDialog: closeSubmitConfirmationDialog,
  } = useDialog();

  const onClickSubmit = React.useCallback(() => {
    const hasChangedValues = JSON.stringify(initialValues) !== JSON.stringify(values);

    if (!isEdit) {
      submitForm();
    } else if (hasChangedValues) {
      openSubmitConfirmationDialog();
    } else {
      closeDialog();
    }
  }, [closeDialog, initialValues, isEdit, openSubmitConfirmationDialog, submitForm, values]);

  const submitFormAfterConfirmation = React.useCallback(() => {
    closeSubmitConfirmationDialog();
    submitForm();
  }, [closeSubmitConfirmationDialog, submitForm]);

  const getInputOptionsAndLabel = React.useCallback<GetInputOptionsAndLabelFunc>(inputName => {
    return EmployeeEmploymentWageFieldOptionsAndLabel[inputName];
  }, []);

  return (
    <>
      {submitConfirmationDialogState && (
        <SubmitConfirmationDialog
          open={submitConfirmationDialogState}
          onClose={closeSubmitConfirmationDialog}
          onConfirm={submitFormAfterConfirmation}
          previousValues={initialValues}
          newValues={values}
          getInputOptionsAndLabel={getInputOptionsAndLabel}
        />
      )}

      {!submitConfirmationDialogState && (
        <FormikModal
          title={
            <FormattedMessage
              id={isEdit ? TranslationKeys.employees_actions_editWage : TranslationKeys.employees_actions_addWage}
            />
          }
          show={open}
          onSubmit={onClickSubmit}
          onCancel={isEdit ? closeDialog : undefined}
          onHide={() => {
            closeDialog();
            resetForm();
          }}
          disableEnforceFocus
        >
          {isEdit && (
            <Typography variant="h6" paragraph>
              {format.date(initialValues.startDate)}
            </Typography>
          )}

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputFieldFactory
                field={{
                  type: 'date',
                  header: EmployeeEmploymentWageFields.StartDate.Label,
                  name: EmployeeEmploymentWageFields.StartDate.InputName,
                  options: EmployeeEmploymentWageFields.StartDate.Options,
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputFieldFactory
                field={{
                  type: 'date',
                  header: EmployeeEmploymentWageFields.EndDate.Label,
                  name: EmployeeEmploymentWageFields.EndDate.InputName,
                  options: EmployeeEmploymentWageFields.EndDate.Options,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputFieldFactory
                field={{
                  header: EmployeeEmploymentWageFields.GrossWage.Label,
                  name: EmployeeEmploymentWageFields.GrossWage.InputName,
                  options: EmployeeEmploymentWageFields.GrossWage.Options,
                  type: 'number',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputFieldFactory
                field={{
                  header: EmployeeEmploymentWageFields.NetWage.Label,
                  name: EmployeeEmploymentWageFields.NetWage.InputName,
                  options: EmployeeEmploymentWageFields.NetWage.Options,
                  type: 'number',
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputFieldFactory
                field={{
                  header: EmployeeEmploymentWageFields.SvWage.Label,
                  name: EmployeeEmploymentWageFields.SvWage.InputName,
                  options: EmployeeEmploymentWageFields.SvWage.Options,
                  type: 'number',
                }}
              />
            </Grid>
          </Grid>
        </FormikModal>
      )}
    </>
  );
};
