import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Box, Grid, Link, Typography } from '@mui/material';

import { normalizeFormError, Uuid } from 'app/utils';

import { TranslationKeys } from 'app/translations';

import type { Insurer } from 'app/types';

import { useInsurerGetInsurerQuery, useSnakeBar } from 'app/hooks';

import type { SelectOwnerInsurersMapSelectedState, TableSelectedState } from 'app/store';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from 'app/store';

import DetailsPanelRow from '../../../../../components/DetailsPanelRow';
import DetailsPanel from '../../../../../components/DetailsPanel';
import { HasAccessTo, IsSuperAdmin } from 'app/components';
import ActionButton from '../../../../../components/ActionButton';

import { actions as InsurerActions } from '../../../Ducks/Insurer.duck';
import insurerService from 'app/Domain/Insurers/Services/InsurerService/insurerService';

import { ProductsTable } from '../../../Components/ProductsTable';
import InsurersContactPersonCard from '../../../Components/InsurersContactPersonCard/InsurersContactCard';
import EditInsurerOwnerFormModal from '../../../Forms/EditInsurerOwnerFormModal';

import { TypographyStyled } from './InsurerInformationTab.styles';
import { INSURER } from '../../../../../common/Authorization/entities';
import { EDIT } from '../../../../../common/Authorization/permissions';

export const InsurerInformationTab = () => {
  const disabledIfNoAccessToInsurerEdit = !HasAccessTo(INSURER, EDIT);

  const dispatch = useStoreDispatch();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const { insurerId } = useParams();

  const [editInsurerOwnerModalOpen, setEditInsurerOwnerModalOpen] = React.useState(false);
  const [isSuperAdmin] = React.useState(IsSuperAdmin());

  const ownerId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));

  const { data: insurer, isLoading: isLoadingInsurer } = useInsurerGetInsurerQuery({
    variables: { insurerId },
    options: {
      enabled: !!insurerId,
    },
  });

  const {
    loading: ownerInsurersLoading,
    map: ownerInsurersMap,
    ownerId: mapOwnerId,
  } = useStoreSelector<SelectOwnerInsurersMapSelectedState>(state =>
    StoreSelectors.InsurersSelector.selectOwnerInsurersMap(state.InsurersReducer),
  );

  const { loading: contactsLoading, items: insurerContacts = [] } = useStoreSelector<
    TableSelectedState<Insurer['contacts']>
  >(state => StoreSelectors.InsurerSelector.selectContacts(state.InsurerReducer));

  const disabledActionsWhileLoading = isLoadingInsurer || ownerInsurersLoading || contactsLoading;
  const insurerOwner = ownerInsurersMap?.[`${insurer?.insurerId}`] ?? null;

  const validOwnerInsurer =
    !!insurerOwner && !ownerInsurersLoading && insurerOwner.insurerId === insurerId && insurerOwner.ownerId === ownerId;

  React.useEffect(() => {
    if (ownerId && ownerId !== mapOwnerId) {
      // @ts-ignore
      dispatch(StoreActions.InsurersActions.ownerInsurers.setOwnerId(ownerId));
    }
  }, [dispatch, mapOwnerId, ownerId]);

  React.useEffect(() => {
    if (validOwnerInsurer) {
      dispatch(
        StoreActions.InsurerActions.contacts.requestData({ ownerId, ownerInsurerId: insurerOwner.ownerInsurerId }),
      );
    }
  }, [dispatch, insurerOwner, ownerId, validOwnerInsurer]);

  const handleClickEdit = React.useCallback(() => {
    setEditInsurerOwnerModalOpen(true);
  }, []);

  const handleCancelInsurer = React.useCallback(() => {
    setEditInsurerOwnerModalOpen(false);
  }, []);

  const handleEditInsurer = React.useCallback(
    async (values, form) => {
      const method = 'editInsurer';

      try {
        const newValues = {
          ...values,
          contacts: values.contacts.map((contact: Record<string, any>) => ({
            ...contact,
            contactId: contact.contactId || Uuid.newV4(),
            ownerInsurerId: insurerOwner?.ownerInsurerId,
          })),
          ownerInsurerId: insurerOwner?.ownerInsurerId,
        };

        if (insurerOwner?.ownerInsurerId) {
          await insurerService.editInsurerOwner(ownerId, insurerOwner.ownerInsurerId, newValues);
          setEditInsurerOwnerModalOpen(false);
          showSuccessSnakeBar({ method });
          form.resetForm();
          dispatch(InsurerActions.contacts.requestData({ ownerId, ownerInsurerId: insurerOwner?.ownerInsurerId }));
        }
      } catch (error: any) {
        const errors = normalizeFormError(error, form);
        if (!errors.violations) {
          setEditInsurerOwnerModalOpen(false);
          showErrorSnakeBar({ method });
          throw error;
        }
      }
    },
    [dispatch, insurerOwner?.ownerInsurerId, ownerId, showErrorSnakeBar, showSuccessSnakeBar],
  );

  const details = React.useMemo(() => {
    const insurerInfoRow = {
      [TranslationKeys.insurers_insurerName]: insurer?.insurerName,
      [TranslationKeys.insurers_address]: insurer?.address,
      [TranslationKeys.insurers_zipCode]: insurer?.zipCode,
      [TranslationKeys.insurers_city]: insurer?.city,
      [TranslationKeys.insurers_insurerEmail]: insurer?.email,
      [TranslationKeys.insurers_insurerPhone]: insurer?.phone,
    };

    const portalLinkRows = (insurer?.portalLinks || []).map(portalLink => (
      <Link href={portalLink} key={portalLink}>
        <Typography variant="body1" color="primary" sx={{ overflowWrap: 'break-word' }}>
          {portalLink}
        </Typography>
      </Link>
    ));

    return (
      <>
        <DetailsPanelRow
          title={
            <TypographyStyled variant="body1">
              <FormattedMessage id={TranslationKeys.insurers_contactInformation} />
            </TypographyStyled>
          }
          rows={insurerInfoRow}
          loading={isLoadingInsurer}
        />

        {portalLinkRows?.length > 0 && (
          <>
            <Box m={1}></Box>
            <DetailsPanelRow
              title={
                <TypographyStyled variant="body1">
                  <FormattedMessage id={TranslationKeys.insurers_portalLinks} />
                </TypographyStyled>
              }
              rows={portalLinkRows}
              loading={isLoadingInsurer}
            />
          </>
        )}

        {!!insurerOwner && (
          <>
            <Box m={1}></Box>
            <DetailsPanelRow
              title={
                <TypographyStyled variant="body1">
                  <FormattedMessage id={TranslationKeys.insurers_agencyNumber} />
                </TypographyStyled>
              }
              rows={{ [TranslationKeys.insurers_agencyNumber]: insurerOwner.agencyNumber }}
              loading={isLoadingInsurer}
            />
          </>
        )}

        {!contactsLoading && insurerContacts?.length > 0 && (
          <>
            <Box m={1}></Box>
            <DetailsPanelRow
              title={
                <TypographyStyled variant="body1">
                  <FormattedMessage id={TranslationKeys.insurers_contactPersons} />
                </TypographyStyled>
              }
              rows={insurerContacts.map((insurerContact, insurerContactIndex) => (
                <InsurersContactPersonCard contactPerson={insurerContact} key={insurerContactIndex} />
              ))}
              loading={isLoadingInsurer}
            />
          </>
        )}
      </>
    );
  }, [contactsLoading, insurer, insurerContacts, insurerOwner, isLoadingInsurer]);

  let actions = null;

  if (!isSuperAdmin) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    actions = React.useMemo(() => {
      return (
        <ActionButton
          messageId={TranslationKeys.insurers_edit}
          disabled={
            disabledActionsWhileLoading || !insurer?.insurerId || isSuperAdmin || disabledIfNoAccessToInsurerEdit
          }
          onClick={handleClickEdit}
        />
      );
    }, [
      disabledActionsWhileLoading,
      disabledIfNoAccessToInsurerEdit,
      handleClickEdit,
      insurer?.insurerId,
      isSuperAdmin,
    ]);
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <DetailsPanel header={undefined} body={details} actions={actions} />
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <ProductsTable insurerId={insurerId || ''} products={insurer?.products || []} loading={isLoadingInsurer} />
        </Grid>
      </Grid>
      {editInsurerOwnerModalOpen && (
        <EditInsurerOwnerFormModal
          insurerOwner={insurerOwner}
          insurer={insurer}
          insurerContacts={insurerContacts}
          open={editInsurerOwnerModalOpen}
          onSubmit={handleEditInsurer}
          onHide={handleCancelInsurer}
        />
      )}
    </>
  );
};
