import React from 'react';
import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { TranslationKeys } from 'app/translations';

import { isNil, parseDate } from 'app/utils';

import type { EmployeeEmploymentWage, UseQueryRefetch } from 'app/types';

import type { ShowSnakeBarParams } from 'app/hooks';
import {
  useEmployeeCreateEmploymentWageMutation,
  useEmployeeUpdateEmploymentWageMutation,
  useSnakeBar,
} from 'app/hooks';

import type {
  CreateEmployeeEmploymentWageArgs,
  UpdateEmployeeEmploymentWageArgs,
} from 'app/Domain/Employees/Services/EmployeeService';

import type { EmployeeEmploymentWageFormValues } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeEmploymentTab/components/EmployeeEmploymentWages/EmployeeEmploymentWages.types';
import { EmployeeEmploymentWageFields } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeEmploymentTab/components/EmployeeEmploymentWages/EmployeeEmploymentWages.types';

import { EmployeeEmploymentWageFormDialogBody } from './components';
import { isAfter, isEqual } from 'date-fns';

type EmployeeEmploymentWageFormDialogProps = {
  open: boolean;
  closeDialog: () => void;
  employerId: string;
  connectionId: string;
  employeeId: string;
  employmentId: string | undefined;
  wage: EmployeeEmploymentWage | null;
  refetchGetEmploymentsQuery: UseQueryRefetch;
  employeeStartDate: string | undefined;
};

export const EmployeeEmploymentWageFormDialog = ({
  open,
  closeDialog,
  employerId,
  connectionId,
  employeeId,
  employmentId,
  wage,
  refetchGetEmploymentsQuery,
  employeeStartDate,
}: EmployeeEmploymentWageFormDialogProps) => {
  const intl = useIntl();
  const { showSnakeBar } = useSnakeBar();
  const { mutate: createEmploymentWage } = useEmployeeCreateEmploymentWageMutation();
  const { mutate: updateEmploymentWage } = useEmployeeUpdateEmploymentWageMutation();

  const isEdit = wage !== null;

  const showSnackBarMessage = React.useCallback(
    ({ method, severity }: Omit<ShowSnakeBarParams, 'message'>) => {
      showSnakeBar({
        severity,
        method,
        message: intl.formatMessage({
          id:
            severity === 'success'
              ? TranslationKeys.global_update_successMessage
              : TranslationKeys.global_update_errorMessage,
        }),
      });
    },
    [intl, showSnakeBar],
  );

  const formikInitialValues = React.useMemo<EmployeeEmploymentWageFormValues>(() => {
    return {
      startDate: wage?.startDate || null,
      endDate: wage?.endDate || null,
      grossWage: wage?.grossWage ?? '',
      netWage: wage?.netWage ?? '',
      svWage: wage?.svWage ?? '',
    };
  }, [wage?.endDate, wage?.grossWage, wage?.netWage, wage?.startDate, wage?.svWage]);

  const formikValidationSchema = React.useMemo(() => {
    return Yup.object().shape({
      [EmployeeEmploymentWageFields.GrossWage.InputName]: Yup.number()
        .optional()
        .when(EmployeeEmploymentWageFields.NetWage.InputName, {
          is: (value: number) => isNil(value),
          then: () => Yup.number().required(),
          otherwise: () => Yup.number().optional(),
        }),
      [EmployeeEmploymentWageFields.StartDate.InputName]: Yup.date().test(
        EmployeeEmploymentWageFields.StartDate.InputName,
        intl.formatMessage({ id: TranslationKeys.employees_error_wageStartDate }),
        value => {
          // Reset hours to 00:00:00
          // Parse "value" to be at the same timezone as employeeStartDate
          const employeeStartDateInstance = new Date(employeeStartDate || 'now');
          employeeStartDateInstance.setHours(0, 0, 0, 0);
          return value
            ? isAfter(parseDate(value), employeeStartDateInstance) ||
                isEqual(parseDate(value), employeeStartDateInstance)
            : false;
        },
      ),
      [EmployeeEmploymentWageFields.EndDate.InputName]: Yup.date()
        .min(
          Yup.ref(EmployeeEmploymentWageFields.StartDate.InputName),
          intl.formatMessage({ id: TranslationKeys.employees_error_wageEndDate }),
        )
        .nullable(),
    });
  }, [employeeStartDate, intl]);

  const formikOnSubmit = React.useCallback<FormikConfig<EmployeeEmploymentWageFormValues>['onSubmit']>(
    (values, { setSubmitting, resetForm }) => {
      const onSuccess = (method: string) => () => {
        setSubmitting(false);
        resetForm();
        closeDialog();
        showSnackBarMessage({ method, severity: 'success' });
        refetchGetEmploymentsQuery();
      };

      const onError = (method: string) => () => {
        setSubmitting(false);
        showSnackBarMessage({ method, severity: 'error' });
      };

      const mutationData = {
        connectionId,
        employeeId,
        employerId,
        employmentId: employmentId!,
        wage: {
          wageId: wage?.wageId,
          externalId: wage?.externalId,
          startDate: values.startDate,
          endDate: values.endDate || null,
          grossWage: values.grossWage === '' ? null : values.grossWage,
          netWage: values.netWage === '' ? null : values.netWage,
        },
      } as CreateEmployeeEmploymentWageArgs | UpdateEmployeeEmploymentWageArgs;

      if (isEdit) {
        const method = 'updateEmploymentWage';
        updateEmploymentWage(mutationData as UpdateEmployeeEmploymentWageArgs, {
          onSuccess: onSuccess(method),
          onError: onError(method),
        });
      } else {
        const method = 'createEmploymentWage';
        createEmploymentWage(mutationData, {
          onSuccess: onSuccess(method),
          onError: onError(method),
        });
      }
    },
    [
      connectionId,
      employeeId,
      employerId,
      employmentId,
      wage?.wageId,
      wage?.externalId,
      isEdit,
      updateEmploymentWage,
      closeDialog,
      showSnackBarMessage,
      refetchGetEmploymentsQuery,
      createEmploymentWage,
    ],
  );

  return (
    <Formik<EmployeeEmploymentWageFormValues>
      initialValues={formikInitialValues}
      validationSchema={formikValidationSchema}
      onSubmit={formikOnSubmit}
    >
      <EmployeeEmploymentWageFormDialogBody open={open} isEdit={isEdit} closeDialog={closeDialog} />
    </Formik>
  );
};
