import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { TranslationKeys } from 'app/translations';

import { useBreadcrumb } from 'app/components';

import { DashboardOverviewPage } from './DashboardOverviewPage';
import { DashboardDataProcessingPage } from './DashboardDataProcessingPage';
import { DashboardUnprocessedEventsPage } from './DashboardUnprocessedEventsPage';
import { DashboardUnprocessedBatchesPage } from './DashboardUnprocessedBatchesPage';
import { DashboardMissingDetailsPage } from './DashboardMissingDetailsPage';
import { EventFlowOverviewPage } from './EventFlowOverviewPage';
import { EventFlowDetailsPage } from './EventFlowDetailsPage';
import { EventFlowDownloadPage } from './EventFlowDownloadPage';
import { DashboardConnectionErrorsDetailsPage } from './DashboardConnectionErrorsDetailsPage';

export const DashboardPages = () => {
  useBreadcrumb(TranslationKeys.menu_dashboard);

  return (
    <Routes>
      <Route index element={<DashboardOverviewPage />} />

      <Route path="data-processing/*">
        <Route index element={<DashboardDataProcessingPage />} />
        <Route path="unprocessed-events" element={<DashboardUnprocessedEventsPage />} />
        <Route path="unprocessed-batches" element={<DashboardUnprocessedBatchesPage />} />
        <Route path="missing-details" element={<DashboardMissingDetailsPage />} />
        <Route path="event-flow" element={<EventFlowOverviewPage />} />
        <Route path="event-flow-details" element={<EventFlowDetailsPage />} />
        <Route path={'event-flow/:encodedFilename'} element={<EventFlowDownloadPage />} />
        <Route path="connection-errors" element={<DashboardConnectionErrorsDetailsPage />} />
        <Route path="*" element={<Navigate to=".." replace />} />
      </Route>

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
