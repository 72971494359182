import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  generateActionTypes,
  tableAction,
  tableActions,
  tableInitialState,
  tableSagas,
} from 'app/Domain/App/Ducks/Table.duck';
import ownerService from '../Services/OwnerService';
import { MESSAGE_SEVERITY_ERROR } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';
import { createSelector } from 'reselect';
import { normalizeError } from 'app/utils';
import {
  generateActionTypes as generateSingleActionTypes,
  singleObjectAction,
  singleObjectActions,
  singleObjectInitialState,
} from 'app/Domain/App/Ducks/SingleObject.duck';
import { convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload } from '../../../utils/convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload';

export const actionTypes = {
  Owner: generateSingleActionTypes('Owner', {
    SetOwner: 'Set Owner',
  }),
  Employers: generateActionTypes('Owner.Employers'),
  EmployeeTypes: generateActionTypes('Owner.EmployeeTypes'),
  SubOwners: generateActionTypes('Owner.SubOwners'),
  AvailablePackageCredentials: generateActionTypes('Owner.AvailablePackageCredentials'),
};

const initialState = {
  owner: { ...singleObjectInitialState },
  employers: { ...tableInitialState },
  employeeTypes: { ...tableInitialState, pageSize: 100 },
  subOwners: { ...tableInitialState },
  availablePackageCredentials: { ...tableInitialState, pageSize: 1000 },
};

export const reducer = (state = initialState, action) => {
  if (action.type.startsWith('[Owner]')) {
    return {
      ...state,
      owner: singleObjectAction(actionTypes.Owner, state.owner, action),
    };
  } else if (action.type.startsWith('[Owner.EmployeeTypes]')) {
    return {
      ...state,
      employeeTypes: tableAction(actionTypes.EmployeeTypes, state.employeeTypes, action, initialState.employeeTypes),
    };
  } else if (action.type.startsWith('[Owner.Employers]')) {
    return {
      ...state,
      employers: tableAction(actionTypes.Employers, state.employers, action, initialState.employers),
    };
  } else if (action.type.startsWith('[Owner.SubOwners]')) {
    return {
      ...state,
      subOwners: tableAction(actionTypes.SubOwners, state.subOwners, action, initialState.subOwners),
    };
  } else if (action.type.startsWith('[Owner.AvailablePackageCredentials]')) {
    return {
      ...state,
      availablePackageCredentials: tableAction(
        actionTypes.AvailablePackageCredentials,
        state.availablePackageCredentials,
        action,
        initialState.availablePackageCredentials,
      ),
    };
  }
  return state;
};

export const actions = {
  owner: singleObjectActions(actionTypes.Owner, {
    setOwner: (owner, onError, onSuccess) => ({
      type: actionTypes.Owner.SetOwner,
      payload: {
        owner,
        onError,
        onSuccess,
      },
    }),
  }),
  employers: tableActions(actionTypes.Employers),
  employeeTypes: tableActions(actionTypes.EmployeeTypes),
  subOwners: tableActions(actionTypes.SubOwners),
  availablePackageCredentials: tableActions(actionTypes.AvailablePackageCredentials),
};

export function* saga() {
  yield takeLatest(actionTypes.Owner.RequestData, function* getOwnerSaga({ payload }) {
    try {
      const { ownerId } = payload;
      const response = yield call(ownerService.getOwner, ownerId);
      const ownerData = response.data;
      if (ownerData.parentId !== null) {
        const parentResponse = yield call(ownerService.getOwner, ownerData.parentId);
        ownerData.parent = parentResponse.data;
      }
      yield put(actions.owner.fulfilledData(ownerData));
    } catch (error) {
      yield put(actions.owner.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'getOwner',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
    }
  });

  yield takeLatest(actionTypes.Owner.SetOwner, function* setOwnerSaga({ payload }) {
    const { owner, onError, onSuccess } = payload;
    try {
      const response = yield call(ownerService.editOwner, owner);
      yield put(actions.owner.fulfilledData(response.data));
      yield onSuccess && onSuccess(owner);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.owner.error(error));
      yield onError && onError(errorData);
    }
  });

  yield takeLatest(actionTypes.Employers.RequestData, function* getOwnerEmployersSaga({ payload }) {
    try {
      const { ownerId } = payload;
      const response = yield call(ownerService.getOwnerEmployers, ownerId);
      yield put(actions.employers.fulfilled(response.data));
    } catch (error) {
      yield put(actions.employers.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'getOwnerEmployers',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
    }
  });

  yield takeLatest(actionTypes.EmployeeTypes.RequestData, function* getEmployeeTypesSaga({ payload }) {
    const pagination = convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload(
      yield select(state => selectors.selectEmployeeTypes(state.OwnerReducer)),
    );
    const { ownerId, onError, onSuccess } = payload;
    try {
      const response = yield call(ownerService.getEmployeeTypes, { ownerId, values: pagination });
      yield put(actions.employeeTypes.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.employeeTypes.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'getEmployeeTypes',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  });

  const requestSubOwnersWorker = function* getSubOwnersSaga({ payload }) {
    const pagination = convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload(
      yield select(state => selectors.selectSubOwners(state.OwnerReducer)),
    );

    const { onError, onSuccess } = payload || {};

    try {
      const userId = yield select(state => {
        return state.AppReducer.user.userId;
      });

      const response = yield call(ownerService.getOwnerSubOwners, { userId, ...pagination });

      yield put(actions.subOwners.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.subOwners.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'getSubOwners',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  };

  yield takeLatest(actionTypes.SubOwners.RequestData, requestSubOwnersWorker);
  yield* tableSagas(actionTypes.SubOwners, requestSubOwnersWorker);

  yield takeLatest(
    actionTypes.AvailablePackageCredentials.RequestData,
    function* requestAvailablePackageCredentialsSaga({ payload }) {
      const pagination = convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload(
        yield select(state => selectors.selectAvailablePackageCredentials(state.OwnerReducer)),
      );
      const { ownerId, packageType, onError, onSuccess } = payload;
      try {
        const response = yield call(ownerService.getOwnerCredentials, {
          ownerId,
          values: { packageType, ...pagination },
        });
        yield put(
          actions.availablePackageCredentials.fulfilled(
            response.data['hydra:member'],
            response.data['hydra:totalItems'],
          ),
        );
        yield onSuccess && onSuccess(response.data);
      } catch (error) {
        const errorData = normalizeError(error);
        yield put(actions.availablePackageCredentials.error(error));
        yield put(
          AppActions.displayError({
            method: 'getAvailablePackageCredentials',
          }),
        );
        yield onError && onError(errorData);
      }
    },
  );
}

export const selectors = {
  selectOwner: createSelector(
    state => state.owner,
    owner => owner,
  ),
  selectOwnerEmployers: createSelector(
    state => state.employers,
    employers => employers,
  ),
  selectEmployeeTypes: createSelector(
    state => state.employeeTypes,
    employeeTypes => employeeTypes,
  ),
  selectSubOwners: createSelector(
    state => state.subOwners,
    subOwners => subOwners,
  ),
  selectAvailablePackageCredentials: createSelector(
    state => state.availablePackageCredentials,
    availablePackageCredentials => availablePackageCredentials,
  ),
};
