import { TranslationKeys } from 'app/translations';
import type { GenderStatusEnum } from 'app/types';

import { normalizeFormFieldsSchemaForGetInputOptionsAndLabelFunc } from 'app/components';

export const EmployeeDetailsPanelFields = {
  SocialSecurityNumber: {
    Label: TranslationKeys.employees_detail_socialSecurityNumber,
    InputName: 'socialSecurityNumber',
    Options: {
      style: 'text',
    },
  },
  PersonnelNumber: {
    Label: TranslationKeys.employees_detail_employeeNumber,
    InputName: 'personnelNumber',
    Options: {
      style: 'text',
    },
  },
  EmployeeTypeId: {
    Label: TranslationKeys.employees_detail_employeeType,
    InputName: 'employeeTypeId',
    Options: {
      style: 'select',
    },
  },
  Gender: {
    Label: TranslationKeys.global_gender,
    InputName: 'gender',
    Options: {
      style: 'select',
    },
  },
  DateOfBirth: {
    Label: TranslationKeys.employees_detail_dateOfBirth,
    InputName: 'dateOfBirth',
    Options: {
      style: 'date',
    },
  },
  PhoneNumber: {
    Label: TranslationKeys.global_phoneNumber,
    InputName: 'phoneNumber',
    Options: {
      style: 'text',
    },
  },
  EmailAddress: {
    Label: TranslationKeys.global_email,
    InputName: 'emailAddress',
    Options: {
      style: 'text',
    },
  },
  CivilStatus: {
    Label: TranslationKeys.global_civilStatus,
    InputName: 'civilStatus',
    Options: {
      style: 'select',
    },
  },
  CostCenter: {
    Label: TranslationKeys.global_costCenter,
    InputName: 'costCenter',
    Options: {
      style: 'text',
    },
  },
  Profession: {
    Label: TranslationKeys.global_profession,
    InputName: 'profession',
    Options: {
      style: 'text',
    },
  },
  PersonName: {
    FieldTypeName: 'personName',
    Props: {
      Initials: {
        Label: TranslationKeys.global_initials,
        FieldTypeName: 'initials',
        InputName: 'personName.initials',
        Options: {
          style: 'text',
        },
      },
      FirstName: {
        Label: TranslationKeys.global_firstName,
        FieldTypeName: 'firstName',
        InputName: 'personName.firstName',
        Options: {
          style: 'text',
        },
      },
      LastName: {
        Label: TranslationKeys.global_lastName,
        FieldTypeName: 'lastName',
        InputName: 'personName.lastName',
        Options: {
          style: 'text',
        },
      },
      LastNamePrefix: {
        Label: TranslationKeys.global_lastNamePrefix,
        FieldTypeName: 'lastNamePrefix',
        InputName: 'personName.lastNamePrefix',
        Options: {
          style: 'text',
        },
      },
    },
  },
  Address: {
    Label: TranslationKeys.global_address,
    FieldTypeName: 'address',
    InputName: 'fullAddress',
    Props: {
      Street: {
        Label: TranslationKeys.global_address_street,
        FieldTypeName: 'street',
        InputName: 'address.street',
        Options: {
          style: 'text',
        },
      },
      HouseNumber: {
        Label: TranslationKeys.global_address_houseNr,
        FieldTypeName: 'houseNumber',
        InputName: 'address.houseNumber',
        Options: {
          style: 'text',
        },
      },
      HouseNumberSuffix: {
        Label: TranslationKeys.global_address_houseNrSuffix,
        FieldTypeName: 'houseNumberSuffix',
        InputName: 'address.houseNumberSuffix',
        Options: {
          style: 'text',
        },
      },
      ZipCode: {
        Label: TranslationKeys.global_address_zipCode,
        FieldTypeName: 'zipCode',
        InputName: 'address.zipCode',
        Options: {
          style: 'text',
        },
      },
      City: {
        Label: TranslationKeys.global_address_city,
        FieldTypeName: 'city',
        InputName: 'address.city',
        Options: {
          style: 'text',
        },
      },
      Country: {
        Label: TranslationKeys.global_address_country,
        FieldTypeName: 'country',
        InputName: 'address.country',
        Options: {
          style: 'autocomplete',
        },
      },
    },
  },
} as const;

export const EmployeeDetailsPanelFormFieldOptionsAndLabel =
  normalizeFormFieldsSchemaForGetInputOptionsAndLabelFunc(EmployeeDetailsPanelFields);

export type EmployeeDetailsPanelFormValues = {
  [EmployeeDetailsPanelFields.SocialSecurityNumber.InputName]: string;
  [EmployeeDetailsPanelFields.PersonnelNumber.InputName]: string;
  [EmployeeDetailsPanelFields.EmployeeTypeId.InputName]: string;
  [EmployeeDetailsPanelFields.Gender.InputName]: GenderStatusEnum;
  [EmployeeDetailsPanelFields.DateOfBirth.InputName]: string;
  [EmployeeDetailsPanelFields.PhoneNumber.InputName]: string;
  [EmployeeDetailsPanelFields.EmailAddress.InputName]: string;
  [EmployeeDetailsPanelFields.CivilStatus.InputName]: string;
  [EmployeeDetailsPanelFields.CostCenter.InputName]: string;
  [EmployeeDetailsPanelFields.Profession.InputName]: string;
  [EmployeeDetailsPanelFields.PersonName.FieldTypeName]: {
    [EmployeeDetailsPanelFields.PersonName.Props.Initials.FieldTypeName]: string;
    [EmployeeDetailsPanelFields.PersonName.Props.FirstName.FieldTypeName]: string;
    [EmployeeDetailsPanelFields.PersonName.Props.LastName.FieldTypeName]: string;
    [EmployeeDetailsPanelFields.PersonName.Props.LastNamePrefix.FieldTypeName]: string;
  };
  [EmployeeDetailsPanelFields.Address.FieldTypeName]: {
    [EmployeeDetailsPanelFields.Address.Props.Street.FieldTypeName]: string;
    [EmployeeDetailsPanelFields.Address.Props.HouseNumber.FieldTypeName]: string;
    [EmployeeDetailsPanelFields.Address.Props.HouseNumberSuffix.FieldTypeName]: string;
    [EmployeeDetailsPanelFields.Address.Props.ZipCode.FieldTypeName]: string;
    [EmployeeDetailsPanelFields.Address.Props.City.FieldTypeName]: string;
    [EmployeeDetailsPanelFields.Address.Props.Country.FieldTypeName]: string;
  };
};
