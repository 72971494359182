import type { CancelTokenSource } from 'axios';
import axios from 'axios';

import { backgroundAxios } from 'app/utils';
import type { UserOwner } from '../../../../types';

let getOwnersToken: CancelTokenSource;

export type GetOwnersParams = {
  page?: number;
  perPage?: number;
  searchQuery?: string;
};

const getOwners = async (params?: GetOwnersParams) => {
  try {
    if (typeof getOwnersToken !== typeof undefined) {
      getOwnersToken.cancel('Operation canceled due to new request.');
    }

    getOwnersToken = axios.CancelToken.source();

    return axios.get('owners', {
      cancelToken: getOwnersToken.token,
      params: {
        ...(params || {}),
        owner: params?.searchQuery,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

const getOwnersTotalCount = async (userId: string) => {
  return axios.get(`users/${userId}/owners-total-count`);
};

const getEnvironmentTotalCount = async (userId: string) => {
  return axios.get(`users/${userId}/environments-total-count`);
};

export type GetOwnerSubOwnersParams = {
  userId: string;
  page?: number;
  perPage?: number;
  searchQuery?: string;
};

const getOwnerSubOwners = async ({ userId, page, perPage, searchQuery }: GetOwnerSubOwnersParams) => {
  return axios.get(`/users/${userId}/sub-owners`, {
    params: {
      page,
      perPage,
      owner: searchQuery,
    },
  });
};

const getAllSubOwners = async (values: Record<string, any>) => {
  return axios.get(`/owners/sub-owners`, { params: { ...values } });
};

const getOwnerEmployers = async (ownerId: string) => {
  return axios.get(`/owners/${ownerId}/employers`);
};

const getOwnerEmployersTotalCount = async (ownerId: string) => {
  return axios.get(`/owners/${ownerId}/employers-total-count`);
};

const getOwner = async (ownerId: string) => {
  return axios.get(`/owners/${ownerId}`);
};

const getUserOwners = async (userId: string, params?: GetOwnersParams) => {
  try {
    if (typeof getOwnersToken !== typeof undefined) {
      getOwnersToken.cancel('Operation canceled due to new request.');
    }

    getOwnersToken = axios.CancelToken.source();

    return axios.get(`users/${userId}/owners`, {
      cancelToken: getOwnersToken.token,
      params: {
        ...(params || {}),
        owner: params?.searchQuery,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export type GetUserEnvironmentParams = {
  userId: UserOwner['userId'] | null | undefined;
};

const getUserEnvironment = async (userId: string | undefined | null, params?: GetUserEnvironmentParams) => {
  try {
    if (typeof getOwnersToken !== typeof undefined) {
      getOwnersToken.cancel('Operation canceled due to new request.');
    }

    getOwnersToken = axios.CancelToken.source();

    return axios.get(`users/${userId}/environments`, {
      cancelToken: getOwnersToken.token,
      params: {
        ...(params || {}),
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

const createOwner = async (values: Record<string, any>) => {
  return axios.post('/owners', { ...values });
};

const editOwner = async (values: Record<string, any>) => {
  return axios.put(`/owners/${values.ownerId}`, { ...values });
};

const deleteOwner = async (ownerId: string) => {
  return axios.delete(`/owners/${ownerId}`);
};

export type GetEmployeeTypesParams = {
  ownerId: string;
  values?: Record<string, any>;
};

const getEmployeeTypes = async ({ ownerId, values = {} }: GetEmployeeTypesParams) => {
  return backgroundAxios.get(`/owners/${ownerId}/settings/employee-types`, { params: { ...values } });
};

type DeleteEmployeeTypeParams = {
  ownerId: string;
  employeeTypeId: string;
};

const deleteEmployeeType = async ({ ownerId, employeeTypeId }: DeleteEmployeeTypeParams) => {
  return backgroundAxios.delete(`/owners/${ownerId}/settings/employee-types/${employeeTypeId}`);
};

const editEmployeeType = async (values: Record<string, any>) => {
  return backgroundAxios.put(`/owners/${values.ownerId}/settings/employee-types/${values.employeeTypeId}`, {
    ...values,
  });
};

const createEmployeeType = async (values: Record<string, any>) => {
  return backgroundAxios.post(`/owners/${values.ownerId}/settings/employee-types`, {
    ...values,
  });
};

type GetOwnerCredentialsParams = {
  ownerId: string;
  values?: Record<string, any>;
};

const getOwnerCredentials = async ({ ownerId, values = {} }: GetOwnerCredentialsParams) => {
  return backgroundAxios.get(`/owners/${ownerId}/credentials`, { params: { ...values } });
};

type ArchiveOwnerConnectionNewEmployeeEventsParams = {
  ownerId: string;
  connectionIds: Array<any>;
  socialSecurityNumbers: Array<any>;
};

const archiveOwnerConnectionNewEmployeeEvents = async ({
  ownerId,
  connectionIds,
  socialSecurityNumbers,
}: ArchiveOwnerConnectionNewEmployeeEventsParams) => {
  return backgroundAxios.post(
    `/owners/${ownerId}/connections/events/archive`,
    {
      connectionIds: connectionIds,
      socialSecurityNumbers: socialSecurityNumbers,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const ownerService = {
  getOwnerCredentials,
  getOwners,
  createOwner,
  getSubOwners: getAllSubOwners,
  editOwner,
  getEmployeeTypes,
  getOwner,
  getOwnerEmployers,
  getOwnerSubOwners,
  getAllSubOwners,
  deleteEmployeeType,
  editEmployeeType,
  createEmployeeType,
  getOwnerEmployersTotalCount,
  getOwnersTotalCount,
  deleteOwner,
  archiveOwnerConnectionNewEmployeeEvents,
  getUserOwners,
  getUserEnvironment,
  getEnvironmentTotalCount,
};
