import { useMutation } from '@tanstack/react-query';
import ConnectionService from '../../../Domain/Connections/Services/ConnectionService';
import type { EventGenerateType } from '../../../types';

type CreateEventsFromDifferencesArgs = {
  connectionId: string;
  batchId: string;
  externalId: string;
  eventType: EventGenerateType;
};

const mutationFn = (data: CreateEventsFromDifferencesArgs) => {
  return ConnectionService.createEvent(data);
};

export const useConnectionCreateEventsFromDifferencesMutation = () => {
  return useMutation<unknown, unknown, CreateEventsFromDifferencesArgs>(mutationFn);
};
